body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
  background: #f2f5fa !important;
}

.cursor-pointer {
  cursor: pointer;
}
.menu-action {
  width: 40px;
  height: 40px;
  margin: 2vh auto;
  border-radius: 10px;
  cursor: pointer;
}

.menu-action:hover,
.menu-action.active {
  background: #29afe8;
}
.menu-action.active::before {
  height: 39px;
  display: block;
  width: 3px;
  background: #74c4e4;
  content: "";
  position: absolute;
  left: 0;
}

.dasboard-icon {
  width: 22px;
  height: 22px;
  background: url(../images/dashboard-icon.png) no-repeat center;
  margin: 8px auto;
}

.menu-action:hover .dasboard-icon,
.menu-action.active .dasboard-icon {
  background: url(../images/dashboard-hover.png) no-repeat center;
}

.inbound-icon {
  width: 23px;
  height: 24px;
  background: url(../images/inbound-icon.png) no-repeat center;
  margin: 8px auto;
}

.menu-action:hover .inbound-icon,
.menu-action.active .inbound-icon {
  background: url(../images/inbound-hover.png) no-repeat center;
}

.outbound-icon {
  width: 23px;
  height: 24px;
  background: url(../images/outbound-icon.png) no-repeat center;
  margin: 8px auto;
}

.menu-action:hover .outbound-icon,
.menu-action.active .outbound-icon {
  background: url(../images/outbound-hover.png) no-repeat center;
}

.inventory-icon {
  width: 24px;
  height: 24px;
  background: url(../images/inventory-management.png) no-repeat center;
  margin: 8px auto;
}

.menu-action:hover .inventory-icon,
.menu-action.active .inventory-icon {
  background: url(../images/inventory-hover.png) no-repeat center;
}

.settings-icon {
  width: 29px;
  height: 29px;
  background: url(../images/account-management.png) no-repeat center;
  margin: 6px auto;
}

.menu-action:hover .settings-icon,
.menu-action.active .settings-icon {
  background: url(../images/account-hover.png) no-repeat center;
}

.inquiry-icon {
  width: 21px;
  height: 24px;
  background: url(../images/inquiry-report.png) no-repeat center;
  margin: 8px auto;
}

.menu-action:hover .inquiry-icon,
.menu-action.active .inquiry-icon {
  background: url(../images/inquiry-hover.png) no-repeat center;
}

.menu-fixed-left {
  border: 1px solid red;
  width: 73px;
  height: 100%;
}

.header {
  background: #122632;
  border-bottom: 2px solid #29afe8;
  min-height: 76px;
}

.logo-separator {
  height: 36px;
  width: 1px;
  background: #43545f;
}

.overwrap-menu {
  padding: 2vh;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.overwrap-menu .menu-column {
  min-height: 72vh;
}
ul.nav li {
  margin-bottom: 10px;
}
ul.nav li section,
.sectionForGrey {
  background: #e9e9e9;
  border-radius: 10px;
  padding: 0px 10px;
}
ul.nav li section ul li,
.sectionForGrey ul li {
  font-weight: 400;
  padding: 0 7px;
  margin-bottom: 6px;
  cursor: pointer;
}
ul.nav li section ul li:hover,
.sectionForGrey ul li:hover,
ul.nav li section ul li.active,
.sectionForGrey ul li.active {
  background: #353535;
  color: #fff;
  border-radius: 3px;
}
.bull-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #122632;
  display: inline-block;
}

.back-arrow {
  width: 22px;
  height: 22px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  background: #74c4e4;
}

.overwrap-menu h6 {
  color: #29afe8;
}

.sidebar-toggler .form-check-input:checked {
  background-color: #29afe8;
  border-color: #29afe8;
}
.sidebar-toggler label {
  font-size: 12px;
}

.sph-breadcrumb {
  height: 28px;
}
.sph-btn {
  background: #ccdc54;
  padding: 8px 35px;
  color: #333;
  display: block;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  min-width: 160px;
  box-sizing: border-box;
}

.sph-btn.sph-shift {
  background: #29afe8;
  color: #fff;
}

.sph-table tr th {
  border: none;
  background: none;
  margin-bottom: 2px;
  font-weight: 500;
}

.sph-table tr td {
  padding: 0.8rem 0.5rem;
  border-bottom: 4px solid #f2f5fa;
}

.sph-checkbox-th {
  border: 2px solid #29afe8 !important;
}
.sph-checkbox {
  border: 2px solid #444 !important;
}

.add-customer {
  height: 31vh;
  border: 2px dashed #29afe8;
  color: #29afe8;
  font-size: 16px;
  background: #fff;
  border-radius: 10px;
}

.grid-view-customer {
  min-height: 26vh;
  color: #333;
  background: #fff;
  border-radius: 10px;
}
.active-grid-button {
  height: 18px;
  line-height: 8px !important;
}

.profile-icon .dropdown-menu {
  left: auto !important;
}

.action-items.dropdown-menu {
  border: 1px solid #eee !important;
  box-shadow: 0px 0px 5px #aaa !important;
}

.action-items.dropdown-menu li {
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  color: #333333;
}

.action-items.dropdown-menu li:hover {
  color: #29afe8;
}

.action-menu.dropdown-toggle::after {
  display: none;
}

.pagination {
  padding: 2px;
}

.pagination li.page-item i {
  padding: 1px 5px;
}

.pagination li.page-item {
  cursor: pointer;
}

.pagination li.page-item span {
  margin: 0 5px !important;
  display: inline-block;
  font-size: 12px;
  padding: 3px 7px;
  font-weight: 500;
}

.pagination li.page-item.active span {
  cursor: default;
  background: #353535 !important;
  color: #fff !important;
}

.pageHeaderItems a {
  text-decoration: none;
}

.grid-toggle a.active,
.grid-toggle a.active:hover {
  background-color: #212529;
  border-color: #1f3453;
}

.grid-toggle a.active svg,
.grid-toggle a.active:hover svg {
  fill: #fff;
}

.grid-toggle a.active:last-child {
  border-radius: 16px !important;
}
.grid-toggle a.active:first-child {
  border-radius: 16px !important;
}
.grid-view-customer strong {
  font-weight: 500;
}

.grid-toggle a:hover {
  background: transparent;
}
.grid-toggle a:hover svg {
  fill: #666;
}
.alert-on-top {
  z-index: 99999;
  top: 10px;
  left: 31%;
  width: 35%;
}
.sph-close {
  background: none !important;
  opacity: 0.2 !important;
}

.sph-close:hover {
  opacity: 0.8 !important;
}

.sph-text-bg-light-grey {
  background: #f2f5fa;
  padding: 4px !important;
}
.sph-text-bg-light-grey svg {
  fill: #666;
}

.tab-item {
  margin-bottom: 0 !important;
  border-left: 1px solid #dcdcdc;
  padding-left: 1px;
  padding-right: 1px;
}
.tab-item:last-child {
  border-right: 1px solid #dcdcdc;
}
.tab-item .nav-link {
  padding: 10px;
  border-bottom: 2px solid #fff;
  color: #ccc;
  cursor: pointer;
}
.tab-item .nav-link:hover {
  color: #000;
}
.tab-item .nav-link.active {
  /* border-bottom: 3px solid #29afe8; */
  color: #000;
  cursor: default;
}
.tab-item svg {
  fill: #eee;
  margin-right: 6px;
}

.tab-item .nav-link.active svg,
.tab-item .nav-link.active:hover svg {
  fill: #29afe8;
}

.tab-item .nav-link.completed,
.tab-item .nav-link.completed svg,
.tab-item .nav-link.active:hover svg {
  fill: #29afe8;
  color: #000;
  cursor: default;
}

.upload-file {
  width: 173px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}

.rounded-checkbox {
  border-radius: 30px !important;
  height: 20px !important;
  width: 20px !important;
  border-color: #74c4e4 !important;
}

.tab-item .nav-link.active-tab {
  border-bottom: 2px solid #29afe8;
  color: #000;
  cursor: default;
}
.multiSelectContainer .search-wrapper {
  border: none;
}

.section-wrapper{
  min-height: 400px;
}